import { JobPhase } from '@/types.ts'
import { formatStringToShortMonthDayYear } from '@/lib/utils.ts'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import theme from '@/theme.ts'
import JobPhaseMenu from './job-phase-menu.tsx'

export default function PhaseHeader({
  phase,
  index,
  phaseVisible,
  editMode,
}: {
  phase: JobPhase
  index: number
  phaseVisible: boolean
  editMode: boolean
}) {
  const expectedPhaseStartDate = formatStringToShortMonthDayYear(phase.expected_start_date as string) || 'TBD'
  return (
    <Stack sx={{ pt: 1 }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
          <Typography fontWeight={700} variant="body2">
            Phase #{index + 1}
          </Typography>
          <Typography fontWeight={400} variant="body2">
            -
          </Typography>
          <Typography fontWeight={400} variant="body2" color={theme.palette.mediumGrey2.main}>
            {phase.name || 'Unnamed Phase'}
          </Typography>
          {!phaseVisible && (
            <Typography fontWeight={400} variant="body2" color={theme.palette.mediumGrey2.main}>
              (Name not visible to vendors)
            </Typography>
          )}
        </Stack>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Typography fontWeight={400} variant="body2">
            Expected Start Date: {expectedPhaseStartDate}
          </Typography>
          {editMode && <JobPhaseMenu />}
        </Stack>
      </Stack>
    </Stack>
  )
}
