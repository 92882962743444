import { type CSSProperties } from 'react'
import { createTheme, type SimplePaletteColorOptions } from '@mui/material'

// Extend the Typography variants
declare module '@mui/material/styles' {
  interface CustomPalette {
    black: SimplePaletteColorOptions
    lightGrey: SimplePaletteColorOptions
    lightGrey2: SimplePaletteColorOptions
    lightGrey3: SimplePaletteColorOptions
    mediumGrey: SimplePaletteColorOptions
    mediumGrey2: SimplePaletteColorOptions
    mediumGrey3: SimplePaletteColorOptions
    mediumGrey4: SimplePaletteColorOptions
    darkGray: SimplePaletteColorOptions
    mediumYellow1: SimplePaletteColorOptions
    mediumGreen1: SimplePaletteColorOptions
    mediumRed1: SimplePaletteColorOptions
    mediumRed2: SimplePaletteColorOptions
    navigation: SimplePaletteColorOptions
    grayStroke: SimplePaletteColorOptions
    mediumGray: SimplePaletteColorOptions
    tableHeaderBackground: SimplePaletteColorOptions
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface Theme {
    borderRadius: {
      xs: string
      sm: string
      md: string
      lg: string
      xl: string
      xxl: string
      circle: string
      pill: string
    }
  }

  interface ThemeOptions {
    borderRadius?: {
      xs?: string
      sm?: string
      md?: string
      lg?: string
      xl?: string
      xxl?: string
      circle?: string
      pill?: string
    }
  }

  interface TypographyVariants {
    modalHeader1: CSSProperties
    modalHeader2: CSSProperties
    modalHeader3: CSSProperties
    tabLabel1: CSSProperties
    tabLabel2: CSSProperties
    tabHeader: CSSProperties
    tabSection: CSSProperties
    tabSectionXl: CSSProperties
    tabSubsection: CSSProperties
    navItem: CSSProperties
    strong: CSSProperties
    strongMd: CSSProperties
    strongLg: CSSProperties
    tableColumnHeader: CSSProperties
    tableColumnHeader2: CSSProperties
    darkGray: CSSProperties
  }

  interface TypographyVariantsOptions {
    modalHeader1?: CSSProperties
    modalHeader2?: CSSProperties
    modalHeader3?: CSSProperties
    tabLabel1?: CSSProperties
    tabLabel2?: CSSProperties
    tabHeader?: CSSProperties
    tabSection?: CSSProperties
    tabSectionXl?: CSSProperties
    tabSubsection?: CSSProperties
    navItem?: CSSProperties
    strong?: CSSProperties
    strongMd?: CSSProperties
    strongLg?: CSSProperties
    tableColumnHeader?: CSSProperties
    tableColumnHeader2?: CSSProperties
    darkGrey?: CSSProperties
    indexAccordionHeader?: CSSProperties
    indexGroupHeader?: CSSProperties
    indexTableItem?: CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    modalHeader1: true
    modalHeader2: true
    modalHeader3: true
    tabLabel1: true
    tabLabel2: true
    tabHeader: true
    tabSection: true
    tabSectionXl: true
    tabSubsection: true
    navItem: true
    strong: true
    strongMd: true
    strongLg: true
    tableColumnHeader: true
    tableColumnHeader2: true
    darkGrey: true
    indexAccordionHeader: true
    indexGroupHeader: true
    indexTableItem: true
  }
}

// Button color overrides
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true
    lightGrey: true
    mediumGrey: true
    darkGray: true
  }
}

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } })

// Convert pixel values to rem based on 16px browser default
const pxToRem = (px: number) => `${px / 16}rem`

// Local Variables For Theme Reference
const colors = {
  mediumGray: '#708391',
  mediumGrayDark: '#4B5862',
  mediumGrayLight: '#7F96A7',
}

export const secondaryFontFamily = 'Acumin Pro, Arial, sans-serif'

const theme = createTheme({
  borderRadius: {
    xs: '4px',
    sm: '8px',
    md: '10px',
    lg: '15px',
    xl: '20px',
    xxl: '28px',
    circle: '100%',
    pill: '9999px',
  },
  palette: {
    primary: createColor('#26A482'),
    secondary: {
      main: '#EEF2F5',
      light: '#F5F7F9',
      dark: '#C6CFD6',
      contrastText: '#000000',
    },
    navigation: createColor('#1E303D'),
    grayStroke: createColor('#C6CFD6'),
    black: createColor('#000000'),
    mediumGray: {
      main: colors.mediumGray,
      light: colors.mediumGrayLight,
      dark: colors.mediumGrayDark,
    },
    lightGrey: createColor('#f5f5f5'),
    lightGrey2: createColor('#C6CFD6'),
    lightGrey3: createColor('#EEF2F5'),
    mediumGrey: createColor('#929292'),
    mediumGrey2: createColor('#708391'),
    mediumGrey3: createColor('#7F96A7'),
    mediumGrey4: createColor('#888888'),
    darkGray: createColor('#1E303D'),
    mediumYellow1: createColor('#FED167'),
    mediumGreen1: createColor('#26A482'),
    mediumRed1: createColor('#C9494B'),
    mediumRed2: createColor('#E10000'),
    action: {
      selected: '#F3F3F3',
    },
    tableHeaderBackground: createColor('#BAD2E440'),
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    // Standard variants
    h1: {
      fontSize: pxToRem(28),
      fontWeight: 600,
    },
    h2: {
      fontSize: pxToRem(22),
      fontWeight: 600,
    },
    h3: {
      fontSize: pxToRem(20),
      fontWeight: 600,
    },
    h4: {
      fontSize: pxToRem(18),
      fontWeight: 600,
    },
    h5: {
      fontSize: pxToRem(16),
      fontWeight: 600,
    },
    h6: {
      fontSize: pxToRem(14),
      fontWeight: 600,
    },
    body1: {
      fontSize: pxToRem(16),
    },
    body2: {
      fontSize: pxToRem(14),
    },
    button: {
      textTransform: 'capitalize',
    },
    // Custom variants
    modalHeader1: {
      fontSize: pxToRem(34),
      fontWeight: 700,
    },
    modalHeader2: {
      fontSize: pxToRem(28),
      fontWeight: 700,
    },
    modalHeader3: {
      fontSize: pxToRem(28),
      fontWeight: 400,
    },
    tabLabel1: {
      fontSize: pxToRem(16),
      fontWeight: 700,
    },
    tabLabel2: {
      fontSize: pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    tabHeader: {
      fontSize: pxToRem(22),
      fontWeight: 700,
    },
    tabSectionXl: {
      fontSize: pxToRem(30),
      fontWeight: 700,
    },
    tabSection: {
      fontSize: pxToRem(16),
      fontWeight: 700,
    },
    tabSubsection: {
      fontSize: pxToRem(13),
      fontWeight: 700,
    },
    subtitle2: {
      fontFamily: secondaryFontFamily,
      color: colors.mediumGray,
      fontSize: pxToRem(14),
      lineHeight: pxToRem(22),
      fontWeight: 400,
    },
    navItem: {
      fontSize: pxToRem(13),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    strong: {
      fontSize: pxToRem(17),
      fontWeight: 700,
    },
    strongMd: {
      fontSize: pxToRem(20),
      fontWeight: 700,
    },
    strongLg: {
      fontSize: pxToRem(24),
      fontWeight: 700,
    },
    tableColumnHeader: {
      fontSize: pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    tableColumnHeader2: {
      fontSize: pxToRem(13),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    indexAccordionHeader: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    indexGroupHeader: {
      fontFamily: secondaryFontFamily,
      fontSize: pxToRem(14),
      fontWeight: 700,
      lineHeight: pxToRem(22),
      textTransform: 'uppercase',
      color: colors.mediumGrayDark,
    },
    indexTableItem: {
      textDecoration: 'underline',
      color: colors.mediumGrayDark,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: ({ theme }) => ({
          borderRadius: theme.borderRadius.sm,
          padding: '10px 20px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.MuiButton-colorPrimary': {
            fontWeight: 700,
          },
          '&.MuiButton-colorSecondary': {
            border: `1px solid ${theme.palette.grayStroke.main}`,
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          modalHeader1: 'h2',
          modalHeader2: 'h2',
          tabLabel1: 'p',
          tabLabel2: 'p',
          tabHeader: 'h3',
          tabSection: 'h4',
          tabSubsection: 'h5',
          navItem: 'p',
          strong: 'span',
          strongLg: 'span',
          tableColumnHeader: 'p',
        },
      },
    },
  },
})

export default theme
