import { z } from 'zod'
/**
 * We are flying the ship as we build it, the backend is ever evolving and doesn't provide types for its responses.
 * Using zod to validate the the frontend assumptions about the responses and enable type safety for usage.
 * In the event of a type mismatch, Zod will throw an error and we can update the schema accordingly.
 */

// NOTE: DateTime Validation is not working as expected, using z.string() for now
const FullTimeStampSchema = z.object({
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
})

// Enum definitions based on backend
const RFPPhaseEnum = z.enum(['bidding', 'buying'])
const RFPSendStatusEnum = z.enum(['pending', 'sent'])
const RFPCustomerStatusEnum = z.enum(['viewed', 'in progress', 'quoted'])
const RFPVendorStatusEnum = z.enum(['new', 'not started', 'in progress', 'quote created'])
export const RFPStateEnum = z.enum(['paused', 'archived', 'closed', 'open'])
const JobStageEnum = z.enum(['PENDING'])

// RFP Settings schema
const RFPSettingsSchema = z.object({
  show_phases_on_jobs_to_vendor: z.boolean().default(false),
  show_job_name_to_vendor: z.boolean().default(false),
})

const ViewedSchema = z.object({
  user_id: z.string(),
  viewed_at: z.string(),
})

const PlantSchema = z.object({
  _id: z.string(),
  common_names: z.array(z.string()),
  display_name: z.string(),
  scientific_name: z.string(),
})

const PlantListEntrySchema = z.object({
  plant: PlantSchema,
  quantity: z.number().optional(),
  job_id: z.string().optional().nullable(),
})

const UserSchema = z.object({
  id: z.string(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
  sub: z.string().nullable().optional(),
  nickname: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  email_verified: z.boolean().nullable().optional(),
  first_name: z.string().nullable().optional(),
  last_name: z.string().nullable().optional(),
  phone_number: z.string().nullable().optional(),
  phone_number_verified: z.boolean().nullable().optional(),
})

const OrganizationSchema = z.object({
  id: z.string(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  deleted_at: z.string().nullable().optional(),
  name: z.string(),
  website: z.string().nullable().optional(),
  created_by: UserSchema.nullable().optional(),
  logo: z.any().nullable().optional(),
  small_logo: z.any().nullable().optional(),
  primary_business_type: z.enum(['buyer', 'seller', 'designer', 'hauler']).nullable().optional(),
  verified: z.boolean().optional(),
  legal_name: z.string().nullable().optional(),
  company_structure: z.enum(['individual', 'llc', 's corp', 'c corp']).nullable().optional(),
  tax_id: z.string().nullable().optional(),
})

const OrganizationMemberSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  organization: OrganizationSchema,
  roles: z.array(z.any()), // Role schema would go here
  invite_status: z.string(),
  user: UserSchema,
})

const JobSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  name: z.string().nullable(),
  organization: OrganizationSchema,
  created_by: UserSchema,
  customer_job_id: z.string().nullable(),
  plants: z.array(z.any()),
  job_date: z.string().nullable(),
  delivery_date: z.string().nullable(),
  stage: JobStageEnum,
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RFPSearchResponseSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string().nullable(),
  deleted_at: z.string().nullable(),
  created_by: UserSchema,
  organization: OrganizationSchema,
  directed_organization: OrganizationSchema,
  name: z.string(),
  plants: z.array(PlantListEntrySchema),
  quote_needed_by: z.string().nullable(),
  owner: OrganizationMemberSchema,
  directed_organization_users: z.array(UserSchema),
  customer_rfp_id: z.string().nullable(),
  delivery_date: z.string().nullable(),
  comments_for_vendor: z.string().nullable(),
  declined: z.boolean(),
  status: z.string(),
  sent_at: z.string().nullable(),
  notification_subject: z.string().nullable(),
  notification_body: z.string().nullable(),
  rfp_settings: z.any().nullable(), // TODO: verify need for deep type
  jobs: z.array(JobSchema),
  organization_id: z.string(),
  directed_organization_id: z.string(),
})
export type RFPSearchResponse = z.infer<typeof RFPSearchResponseSchema>

const BaseRFPIndexRFPSchema = z.object({
  ...FullTimeStampSchema.shape,
  rfp_id: z.string(),
  sent_rfp_id: z.string().nullable(),
  name: z.string(),
  flagged_as_important_by_customer: z.boolean().default(false),
  flagged_as_important_by_vendor: z.boolean().default(false),
  quote_needed_by: z.string().nullable(),
  delivery_date: z.string().nullable(),
  customer_rfp_id: z.string().nullable(),
  last_viewed_by_customer: z.string().nullable(),
  last_viewed_by_vendor: z.string().nullable(),
  last_viewed_customer_user: z.any().nullable(), // TODO: verify need for deep type
  last_viewed_vendor_user: z.any().nullable(), // TODO: verify need for deep type
  unread_change_by_customer: z.boolean().default(false),
  unread_change_by_vendor: z.boolean().default(false),
  unread_comments_by_customer: z.number().default(0),
  unread_comments_by_vendor: z.number().default(0),
  sent_at: z.string().nullable(),
  state: RFPStateEnum.nullable(),
  status: RFPSendStatusEnum,
  rfp_settings: RFPSettingsSchema.nullable(),
  total_comments: z.number().default(0),
  vendor_status: RFPVendorStatusEnum.nullable(),
  customer_status: RFPCustomerStatusEnum.nullable(),
})

const RFPContactSchema = z.object({
  vendor_id: z.string().nullable(),
  customer_id: z.string().nullable(),
  organization_name: z.string().nullable(),
  contact_organization_id: z.string().nullable(),
})
export type RFP = z.infer<typeof BaseRFPIndexRFPSchema>

const RFPJobSchema = z.object({
  job_id: z.string().nullable(),
  job_name: z.string().nullable(),
  job_date: z.coerce.date().nullable(),
})

/**
 * Minimal Schema for a grouped vendor
 */
const GroupedContactSchema = z.object({
  id: z.string(),
  organization_name: z.string(),
  favorite: z.boolean(),
  vendor: z.boolean(),
  customer: z.boolean(),
  organization: OrganizationSchema,
})

export const RFPContactIndexSchema = z.array(
  z.object({
    id: z.string(),
    ...FullTimeStampSchema.shape,
    organization: z.any(), // TODO: verify need for deep type
    vendor: GroupedContactSchema.nullable(), // TODO: verify need for deep type
    customer: GroupedContactSchema.nullable(), // TODO: verify need for deep type
    rfps: z
      .array(
        BaseRFPIndexRFPSchema.extend({
          jobs: z.array(RFPJobSchema).default([]),
        })
      )
      .default([]),
  })
)

export type RFPContactIndex = z.infer<typeof RFPContactIndexSchema>

const RFPJobIndexJobSchema = z.object({
  ...FullTimeStampSchema.shape,
  id: z.string(),
  name: z.string().nullable(),
  delivery_date: z.string().nullable(),
  stage: JobStageEnum.nullable(),
  customer_job_id: z.string().nullable(),
  phases: z.array(z.any()).default([]),
})

export const RFPJobIndexSchema = z.array(
  z.object({
    id: z.string(),
    ...FullTimeStampSchema.shape,
    organization: OrganizationSchema,
    job: RFPJobIndexJobSchema.nullable(),
    rfps: z
      .array(
        BaseRFPIndexRFPSchema.extend({
          contacts: z.array(RFPContactSchema).default([]),
        })
      )
      .default([]),
  })
)

export type RFPJobIndex = z.infer<typeof RFPJobIndexSchema>

// This schema matches the RFPGet response from the backend
export const RFPIndexSchema = z.array(
  z.object({
    id: z.string(),
    created_at: z.string(),
    updated_at: z.string().nullable(),
    deleted_at: z.string().nullable(),
    created_by: UserSchema,
    organization: OrganizationSchema,
    name: z.string().nullable(),
    phase: RFPPhaseEnum.nullable(),
    plants: z.array(z.any()).optional(),
    quote_needed_by: z.string().nullable(),
    collaborators: z.array(OrganizationMemberSchema).optional(),
    owner: OrganizationMemberSchema.nullable().optional(),
    customer_rfp_id: z.string().nullable(),
    delivery_date: z.string().nullable(),
    notes: z.string().nullable().optional(),
    comments_for_vendor: z.string().nullable().optional(),
    status: RFPSendStatusEnum,
    last_viewed_user: UserSchema.nullable(),
    last_viewed_at: z.string().nullable(),
    viewed: z.array(ViewedSchema).optional(),
    jobs: z.array(JobSchema).optional().default([]),
    rfp_settings: RFPSettingsSchema.optional(),
    flagged_as_important: z.boolean().default(false),
    state: RFPStateEnum.nullable().optional(),
    sent: z.string().nullable().optional(),
    unread_change_by_customer: z.boolean().default(false),
    unread_change_by_vendor: z.boolean().default(false),
    unread_comments_by_customer: z.number().default(0),
    unread_comments_by_vendor: z.number().default(0),
    total_comments: z.number().default(0),
    vendor_status: RFPVendorStatusEnum.optional().nullable(),
    customer_status: RFPCustomerStatusEnum.optional().nullable(),
  })
)

export const RFPIndexOptionsSchema = z.object({
  type: z.enum(['received', 'sent']).optional(),
  groupBy: z.enum(['status', 'contact', 'job']).optional(),
  page: z.number().optional(),
  status: RFPStateEnum.optional(),
  limit: z.number().optional(),
  search: z.string().optional(),
})

// This schema matches the SentRFPGet response from the backend
const SentRFPResponseSchema = z.object({
  id: z.string(),
  ...FullTimeStampSchema.shape,
  created_by: UserSchema,
  organization: OrganizationSchema,
  directed_organization: OrganizationSchema.optional(),
  organization_contact: z.any(), // OrganizationContactResponseSchema
  directed_organization_contact: z.any(), // OrganizationContactResponseSchema
  name: z.string().optional(),
  phase: RFPPhaseEnum.optional(),
  vendor_status: RFPVendorStatusEnum.optional(),
  customer_status: RFPCustomerStatusEnum.optional().nullable(),
  plants: z.array(z.any()).optional(),
  quote_needed_by: z.string().optional(),
  collaborators: z.array(OrganizationMemberSchema),
  collaborator_user_contacts: z.array(z.any()), // UserContactResponseSchema
  owner: OrganizationMemberSchema.optional(),
  owner_contact: z.any().optional(), // UserContactResponseSchema
  customer_rfp_id: z.string().optional().nullable(),
  rfp_id: z.string(),
  delivery_date: z.string().optional().nullable(),
  comments_for_vendor: z.string().optional().nullable(),
  directed_organization_user_contacts: z.array(z.any()), // UserContactResponseSchema
  directed_organization_users: z.array(OrganizationMemberSchema),
  last_viewed_user: UserSchema.optional().nullable(),
  last_viewed_at: z.string().optional().nullable(),
  status: RFPSendStatusEnum,
  viewed: z.array(ViewedSchema),
  jobs: z.array(JobSchema),
  sent_at: z.string().optional().nullable(),
  rfp_settings: RFPSettingsSchema,
  notification_subject: z.string().optional(),
  notification_body: z.string().optional().nullable(),
  flagged_as_important_by_vendor: z.boolean().default(false),
  flagged_as_important_by_customer: z.boolean().default(false),
  state: RFPStateEnum.nullable(),
  unread_change_by_vendor: z.boolean().default(false),
  unread_change_by_customer: z.boolean().default(false),
  total_comments: z.number().default(0),
})

export const SentRFPResponseArraySchema = z.array(SentRFPResponseSchema)

const statusGroup = z.object({
  groupBy: z.literal('status'),
  data: SentRFPResponseArraySchema,
  hasMore: z.boolean(),
})

const contactGroup = z.object({
  groupBy: z.literal('contact'),
  data: RFPContactIndexSchema,
  hasMore: z.boolean(),
})
const jobGroup = z.object({
  groupBy: z.literal('job'),
  data: RFPJobIndexSchema,
  hasMore: z.boolean(),
})

const _draftGroup = z.object({
  data: RFPIndexSchema,
  hasMore: z.boolean(),
})

// Create the unions using the defined members
export const RFPIndexUnionSchema = z.discriminatedUnion('groupBy', [statusGroup, contactGroup, jobGroup])
export const RFPIndexGroupedUnionSchema = z.discriminatedUnion('groupBy', [contactGroup, jobGroup])

export type RFPIndexUnion = z.infer<typeof RFPIndexUnionSchema>
export type RFPIndexGroupedUnion = z.infer<typeof RFPIndexGroupedUnionSchema>
export type RFPIndexStatus = z.infer<typeof statusGroup>
export type RFPIndexOptions = z.infer<typeof RFPIndexOptionsSchema>
export type RFPIndexDrafts = z.infer<typeof _draftGroup>
