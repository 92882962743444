import { createContext, useContext, useState, useCallback, ReactNode, MouseEvent, useEffect } from 'react'
import { excludeDeletedItems, generateObjectId } from '@/lib/utils'
import { DEFAULT_PLANT_LIST_ENTRY } from '@/constants'
import type {
  Job,
  PlantListEntry,
  PlantWithQuantity,
  RequestForProposal,
  AddPlantsFromJobModalParamType,
  ConfirmationModalProps,
  SentRFP,
} from '@/types.ts'

interface RfpContextType {
  rfpData: RequestForProposal
  updateRfpData: (updates: any) => Promise<void>
  sentRfp?: SentRFP
  sentRfpData: SentRFP | undefined | null
  updateSentRfpData?: (params: { sentRfpId: string; updates: any }) => Promise<void>
  activeJobId: string
  setActiveJobId: (jobId: string) => void
  isAddingJob: boolean
  addJobsToRfp: (jobIds: Set<string>) => Promise<void>
  removeJobFromRfp: (jobId: string) => void
  addPlants: (plants: PlantWithQuantity[] | PlantListEntry[]) => Promise<void>
  updatePlants: (updatedPlants: PlantListEntry[]) => void
  isPlantWithQuantity: (plant: PlantWithQuantity | PlantListEntry) => plant is PlantWithQuantity
  selectedPlantIds: Set<string>
  handleSelectionChange: (selectedIds: Set<string>) => void
  removeSelectedPlants: () => void
  addPlantsFromJobModalProps: AddPlantsFromJobModalParamType
  addPlantsFromListModalOpen: boolean
  createPlantListModalOpen: boolean
  addPlantsModalOpen: boolean
  editRfpDetailsModalOpen: boolean
  addPhaseToJobRfpModalOpen: boolean
  addJobsToRfpModalOpen: string
  confirmationModalContent: ConfirmationModalProps | null
  setSelectedPlantIds?: (selectedIds: Set<string>) => void
  setAddPlantsFromJobModalProps: (props: AddPlantsFromJobModalParamType) => void
  setAddPlantsFromListModalOpen: (open: boolean) => void
  setCreatePlantListModalOpen: (open: boolean) => void
  setAddPlantsModalOpen: (open: boolean) => void
  setEditRfpDetailsModalOpen: (open: boolean) => void
  setAddPhaseToJobRfpModalOpen: (open: boolean) => void
  setAddJobsToRfpModalOpen: (open: string) => void
  setConfirmationModalContent: (content: ConfirmationModalProps | null) => void
  closeAllModals: () => void
  jobPlantsMenuAnchorEl: null | HTMLElement
  jobMenuAnchorEl: null | HTMLElement
  phaseMenuAnchorEl: null | HTMLElement
  handlePlantMenuClick: (event: MouseEvent<HTMLElement>, jobId: string) => void
  handlePlantMenuClose: () => void
  handleJobMenuClick: (event: MouseEvent<HTMLElement>, jobId: string) => void
  handleJobMenuClose: () => void
  handlePhaseMenuClick: (event: MouseEvent<HTMLElement>) => void
  handlePhaseMenuClose: () => void
}

const RfpContext = createContext<RfpContextType | undefined>(undefined)

export function RfpProvider({
  children,
  initialRfpData,
  initialSentRfpData,
  onUpdateRfpData,
  sentRfp,
  initialSelectedPlantIds,
  onUpdateSentRfpData,
}: {
  children: ReactNode
  initialRfpData: RequestForProposal
  initialSentRfpData?: SentRFP | null
  onUpdateRfpData: (updates: any) => Promise<void> | void
  sentRfp?: SentRFP
  initialSelectedPlantIds?: string[]
  onUpdateSentRfpData?: (params: { sentRfpId: string; updates: any }) => Promise<void> | void
}) {
  const [rfpData, setRfpData] = useState<RequestForProposal>(initialRfpData)
  // console.log('RFP Context :: rfpData', rfpData)
  const [sentRfpData, setSentRfpData] = useState<SentRFP | undefined | null>(initialSentRfpData)
  const [activeJobId, setActiveJobId] = useState('')
  const [isAddingJob, setIsAddingJob] = useState(false)
  const [_pendingJobIds, setPendingJobIds] = useState<Set<string>>(new Set())
  const [selectedPlantIds, setSelectedPlantIds] = useState<Set<string>>(new Set(initialSelectedPlantIds || []))
  const [addPlantsFromJobModalProps, setAddPlantsFromJobModalProps] = useState<AddPlantsFromJobModalParamType>({})
  const [addPlantsFromListModalOpen, setAddPlantsFromListModalOpen] = useState(false)
  const [createPlantListModalOpen, setCreatePlantListModalOpen] = useState(false)
  const [addPlantsModalOpen, setAddPlantsModalOpen] = useState(false)
  const [editRfpDetailsModalOpen, setEditRfpDetailsModalOpen] = useState(false)
  const [addPhaseToJobRfpModalOpen, setAddPhaseToJobRfpModalOpen] = useState(false)
  const [addJobsToRfpModalOpen, setAddJobsToRfpModalOpen] = useState('')
  const [confirmationModalContent, setConfirmationModalContent] = useState<ConfirmationModalProps | null>(null)
  const [jobPlantsMenuAnchorEl, setJobPlantsMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [jobMenuAnchorEl, setJobMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [phaseMenuAnchorEl, setPhaseMenuAnchorEl] = useState<null | HTMLElement>(null)

  // NOTE: I feel like these useEffect calls shouldn't be necessary,
  // but they keep changes from query updates and state in sync.
  useEffect(() => {
    if (initialRfpData) {
      setRfpData(initialRfpData)
    }
  }, [initialRfpData])

  useEffect(() => {
    if (initialSentRfpData) {
      setSentRfpData(initialSentRfpData)
    }
  }, [initialSentRfpData])

  const updateRfpData = useCallback(
    async (updates: any) => {
      setRfpData((prev) => ({ ...prev, ...updates }))

      try {
        await onUpdateRfpData(updates)
      } catch (error) {
        console.error('Failed to update RFP data:', error)
      }
    },
    [onUpdateRfpData]
  )

  const updateSentRfpData = useCallback(
    async (params: { sentRfpId: string; updates: any }) => {
      if (sentRfp && onUpdateSentRfpData) {
        setSentRfpData((prev) => ({ ...prev, ...params.updates }))
        try {
          await onUpdateSentRfpData(params)
        } catch (error) {
          console.error('Failed to update sent RFP data:', error)
        }
      }
    },
    [sentRfp, onUpdateSentRfpData]
  )

  const addJobsToRfp = useCallback(
    async (jobIds: Set<string>) => {
      if (jobIds.size > 0) {
        setIsAddingJob(true)
        setPendingJobIds(jobIds)

        try {
          const existingJobsIds = excludeDeletedItems(rfpData?.jobs).map((job: Job) => job.id)
          const updatedJobIds = new Set([...existingJobsIds, ...jobIds])
          const updatedRfpData = { ...rfpData, jobs: Array.from(updatedJobIds) }

          await updateRfpData(updatedRfpData)

          setAddJobsToRfpModalOpen('')
        } catch (error) {
          console.error('Failed to add jobs to RFP:', error)
        } finally {
          setIsAddingJob(false)
        }
      }
    },
    [rfpData, updateRfpData, setAddJobsToRfpModalOpen]
  )

  const removeJobFromRfp = useCallback(
    (jobId: string) => {
      const updatedPlantEntries = (rfpData?.plants || []).map((entry) =>
        entry.job_id === jobId && entry.deleted_at === null ? { ...entry, deleted_at: new Date().toISOString() } : entry
      )
      const updatedJobs = (rfpData?.jobs || []).filter((job: any) => {
        // Ensure we're working with a Job object, not a string
        if (typeof job === 'string') return job !== jobId
        return job.id !== jobId
      })
      updateRfpData({ plants: updatedPlantEntries, jobs: updatedJobs })
      setActiveJobId('')
    },
    [rfpData, updateRfpData]
  )

  const isPlantWithQuantity = useCallback((plant: PlantWithQuantity | PlantListEntry): plant is PlantWithQuantity => {
    return (plant as PlantWithQuantity).plant !== undefined
  }, [])

  const updatePlants = useCallback(
    (updatedPlants: PlantListEntry[]) => {
      const existingJobIds = rfpData.jobs.map((job) => {
        if (typeof job === 'string') return job
        return job.id
      })
      const jobIds = updatedPlants.reduce((acc, plant) => {
        if (plant.job_id && !plant.deleted_at) acc.add(plant.job_id)
        return acc
      }, new Set<string>())
      if (sentRfp && onUpdateSentRfpData) {
        updateSentRfpData({ sentRfpId: sentRfp.id, updates: { plants: updatedPlants } })
      } else {
        updateRfpData({ ...rfpData, jobs: [...existingJobIds, ...Array.from(jobIds)], plants: updatedPlants })
      }
      setActiveJobId('')
    },
    [sentRfp, updateSentRfpData, updateRfpData]
  )

  const addPlants = useCallback(
    async (plants: PlantWithQuantity[] | PlantListEntry[]): Promise<void> => {
      const existingPlants = [...(rfpData?.plants || [])]
      const defaultPhaseId = null
      const jobId = activeJobId || ''

      // Process each plant to either restore a deleted plant or create a new one
      const processedPlants = plants.map((plant) => {
        // For PlantWithQuantity type, create a new plant entry
        if (isPlantWithQuantity(plant)) {
          return {
            ...DEFAULT_PLANT_LIST_ENTRY,
            id: generateObjectId(),
            job_id: jobId,
            phase_id: plant.phase_id || defaultPhaseId,
            quantity_count: { min: plant.quantity, max: null },
            scientific_name: plant.plant.scientific_name,
            common_name: plant.plant.common_names[0],
            parent_of_order: null,
          }
        }

        // For PlantListEntry type, find and restore if it exists, otherwise use as is
        const existingEntry = existingPlants.find((entry) => entry.id === plant.id)
        if (existingEntry) {
          return {
            ...existingEntry,
            deleted_at: null,
            job_id: jobId || existingEntry.job_id,
            phase_id: plant.phase_id || defaultPhaseId,
          }
        }

        return {
          ...plant,
          job_id: jobId || plant.job_id,
          phase_id: plant.phase_id || defaultPhaseId,
          parent_of_order: null,
        }
      })

      // Update the plant list while maintaining order
      const updatedPlantEntries = existingPlants.map((plant) => {
        const processedPlant = processedPlants.find((p) => p.id === plant.id)
        return processedPlant || plant
      })

      // Add any new plants that weren't updates to existing ones
      const newPlants = processedPlants.filter((plant) => !existingPlants.some((existing) => existing.id === plant.id))

      if (newPlants.length > 0) {
        // Update the parent_of_order for the current last plant
        const currentLastEntry = updatedPlantEntries.find((entry) => entry.parent_of_order === null)
        if (currentLastEntry) {
          currentLastEntry.parent_of_order = newPlants[0].id
        }

        // Set up parent_of_order chain for new plants
        newPlants.forEach((plant, index) => {
          plant.parent_of_order = index === newPlants.length - 1 ? null : newPlants[index + 1].id
        })

        updatedPlantEntries.push(...newPlants)
      }

      setActiveJobId('')
      updatePlants(updatedPlantEntries)
      return Promise.resolve()
    },
    [rfpData, activeJobId, isPlantWithQuantity, updatePlants]
  )

  const handleSelectionChange = useCallback(
    (selectedIds: Set<string>) => {
      setSelectedPlantIds(selectedIds)

      if (sentRfp && onUpdateSentRfpData) {
        const updatedPlants = rfpData?.plants.map((plant: PlantListEntry) =>
          selectedIds.has(plant.id)
            ? { ...plant, deleted_at: null }
            : { ...plant, deleted_at: new Date().toISOString() }
        )
        updateSentRfpData({ sentRfpId: sentRfp.id, updates: { plants: updatedPlants } })
      }
    },
    [onUpdateSentRfpData, rfpData?.plants, sentRfp, updateSentRfpData]
  )

  const removeSelectedPlants = useCallback(() => {
    const updatedPlantEntries = (rfpData?.plants || []).map((entry) => {
      if (selectedPlantIds.has(entry.id)) {
        return { ...entry, deleted_at: new Date().toISOString() }
      }
      return entry
    })

    if (sentRfp && onUpdateSentRfpData) {
      updateSentRfpData({ sentRfpId: sentRfp.id, updates: { plants: updatedPlantEntries } })
    } else {
      updateRfpData({ plants: updatedPlantEntries })
    }
  }, [onUpdateSentRfpData, rfpData?.plants, selectedPlantIds, sentRfp, updateRfpData, updateSentRfpData])

  // Modal functions
  const closeAllModals = useCallback(() => {
    setAddPlantsFromJobModalProps({})
    setAddPlantsFromListModalOpen(false)
    setCreatePlantListModalOpen(false)
    setAddPlantsModalOpen(false)
    setEditRfpDetailsModalOpen(false)
    setAddPhaseToJobRfpModalOpen(false)
    setAddJobsToRfpModalOpen('')
    setConfirmationModalContent(null)
  }, [])

  // New handlers for menu
  const handlePlantMenuClick = useCallback(
    (event: MouseEvent<HTMLElement>, jobId: string) => {
      setJobPlantsMenuAnchorEl(event.currentTarget)
      setActiveJobId(jobId)
    },
    [setActiveJobId]
  )

  const handlePlantMenuClose = useCallback(() => {
    setJobPlantsMenuAnchorEl(null)
  }, [])

  const handleJobMenuClick = useCallback(
    (event: MouseEvent<HTMLElement>, jobId: string) => {
      setJobMenuAnchorEl(event.currentTarget)
      setActiveJobId(jobId)
    },
    [setActiveJobId]
  )

  const handleJobMenuClose = useCallback(() => {
    setJobMenuAnchorEl(null)
  }, [])

  const handlePhaseMenuClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setPhaseMenuAnchorEl(event.currentTarget)
  }, [])

  const handlePhaseMenuClose = useCallback(() => {
    setPhaseMenuAnchorEl(null)
  }, [])

  const contextValue: RfpContextType = {
    // RFP Data
    rfpData,
    updateRfpData,
    sentRfp,
    sentRfpData,
    updateSentRfpData,

    // Job Management
    activeJobId,
    setActiveJobId,
    isAddingJob,
    addJobsToRfp,
    removeJobFromRfp,

    // Plant Management
    addPlants,
    updatePlants,
    isPlantWithQuantity,

    // Plant Selection
    selectedPlantIds,
    handleSelectionChange,
    removeSelectedPlants,

    // Modals
    addPlantsFromJobModalProps,
    addPlantsFromListModalOpen,
    createPlantListModalOpen,
    addPlantsModalOpen,
    editRfpDetailsModalOpen,
    addPhaseToJobRfpModalOpen,
    addJobsToRfpModalOpen,
    confirmationModalContent,

    setSelectedPlantIds,
    setAddPlantsFromJobModalProps,
    setAddPlantsFromListModalOpen,
    setCreatePlantListModalOpen,
    setAddPlantsModalOpen,
    setEditRfpDetailsModalOpen,
    setAddPhaseToJobRfpModalOpen,
    setAddJobsToRfpModalOpen,
    setConfirmationModalContent,
    closeAllModals,

    // Menus
    jobPlantsMenuAnchorEl,
    jobMenuAnchorEl,
    phaseMenuAnchorEl,
    handlePlantMenuClick,
    handlePlantMenuClose,
    handleJobMenuClick,
    handleJobMenuClose,
    handlePhaseMenuClick,
    handlePhaseMenuClose,
  }

  return <RfpContext.Provider value={contextValue}>{children}</RfpContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export function useRfpContext() {
  const context = useContext(RfpContext)
  if (context === undefined) {
    throw new Error('useRfpContext must be used within an RfpProvider')
  }
  return context
}
