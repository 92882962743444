import { excludeDeletedItems, groupByKey } from '@/lib/utils.ts'
import { Job, JobPhase, PlantListEntry } from '@/types.ts'
import RfpPlantTable, { RfpPhaseJobPlantGroup } from '@/components/rfps/rfp-plant-table.tsx'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'

import PhaseHeader from './phase-header.tsx'
import RfpJobPhaseDefaultContent from './rfp-job-phase-default-content.tsx'
import RfpJobDefaultContent from './rfp-job-default-content.tsx'
import AddMissingPlantsFromJobButton from './add-missing-plants-from-job-button.tsx'
import { useRfpContext } from '@/components/rfps/context/rfp-context.tsx'

export default function RfpJobPhasePlantTable({
  jobId,
  editMode,
  phaseVisible,
  plantsByJobGroup,
  jobGroups,
  showDeleted = false,
  allowPlantTableReorder = false,
  allowPlantTableQuantityChange = false,
  allowPlantTableSelect = false,
  showPlantTableMenu = false,
}: {
  jobId: string
  editMode: boolean
  phaseVisible: boolean
  plantsByJobGroup: Record<string, PlantListEntry[]>
  jobGroups: Record<string, Job>
  showDeleted?: boolean
  allowPlantTableReorder?: boolean
  allowPlantTableQuantityChange?: boolean
  allowPlantTableSelect?: boolean
  showPlantTableMenu?: boolean
}) {
  const { handleSelectionChange, selectedPlantIds, sentRfp, updatePlants } = useRfpContext()
  if (!jobId) return null

  const jobData = jobGroups[jobId]
  const jobPhaseIds = excludeDeletedItems(jobData?.phases).map((phase: JobPhase) => phase.id)
  const groupedPhasePlants = groupByKey(plantsByJobGroup[jobId], 'phase_id') || {}

  let phaseJobPlantGroup = excludeDeletedItems(jobData.phases).map((phase: JobPhase, index: number) => {
    return {
      job_id: jobId,
      phase_id: phase.id,
      header: (
        <PhaseHeader
          key={`phase-header-${phase.id}-${sentRfp?.id}`}
          phase={phase}
          index={index}
          editMode={editMode}
          phaseVisible={phaseVisible}
        />
      ),
      plants: groupedPhasePlants[phase.id as string] || [],
      defaultContent: <RfpJobPhaseDefaultContent jobId={jobId} phaseId={phase.id as string} />,
    } as RfpPhaseJobPlantGroup
  })

  let plantsWithoutPhase: PlantListEntry[] = []
  Object.entries(groupedPhasePlants as Record<string, PlantListEntry[]>).forEach(
    ([phaseId, phasePlants]: [string, PlantListEntry[]]) => {
      if (jobPhaseIds.includes(phaseId)) return
      plantsWithoutPhase = [...plantsWithoutPhase, ...phasePlants]
    }
  )
  if (plantsWithoutPhase.length) {
    phaseJobPlantGroup.push({
      job_id: jobId,
      phase_id: 'null',
      header: phaseJobPlantGroup.length ? (
        <Typography variant="body2" fontWeight={700}>
          Unassigned Plants
        </Typography>
      ) : null,
      plants: plantsWithoutPhase,
      defaultContent: phaseJobPlantGroup.length ? null : <RfpJobDefaultContent jobId={jobId} jobGroups={jobGroups} />,
    } as RfpPhaseJobPlantGroup)
  }

  const handlePhasePlantUpdate = (updatedPlants: PlantListEntry[]) => {
    let updatedPlantWithParentOrder: any[] = []
    Object.entries(plantsByJobGroup).forEach(([job_id, plants]) => {
      const jobPlants = (job_id === jobId ? updatedPlants : plants) as PlantListEntry[]
      updatedPlantWithParentOrder = updatedPlantWithParentOrder.concat(
        jobPlants.map((plant, index) => {
          if (index === 0 && updatedPlantWithParentOrder.length > 0) {
            updatedPlantWithParentOrder[updatedPlantWithParentOrder.length - 1].parent_of_order = plant.id
          }

          const parent_of_order = index === jobPlants.length - 1 ? null : jobPlants[index + 1].id
          return { ...plant, parent_of_order }
        })
      )
    })
    updatePlants(updatedPlantWithParentOrder)
  }

  return (
    <Stack key={`phase-plants-${jobId}-${sentRfp?.id}`}>
      <RfpPlantTable
        phaseJobPlantGroup={phaseJobPlantGroup}
        onUpdate={handlePhasePlantUpdate}
        onSelectionChange={handleSelectionChange}
        preSelectedPlants={selectedPlantIds}
        allowReorder={allowPlantTableReorder}
        allowQuantityChange={allowPlantTableQuantityChange}
        allowSelect={allowPlantTableSelect}
        showMenu={showPlantTableMenu}
        showDeleted={showDeleted}
      />
      {editMode && (
        <AddMissingPlantsFromJobButton jobId={jobId} plantsByJobGroup={plantsByJobGroup} jobGroups={jobGroups} />
      )}
    </Stack>
  )
}
