import { SxProps } from '@mui/material'
import { ReactNode, useState, MouseEvent } from 'react'
import { CommentPage } from '@/types/comments.ts'
import { useCommentStore } from '@/stores/comment-store.ts'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CommentUI from '@/components/rfps/comment-ui.tsx'
import { useComments } from '@/hooks/useComments.ts'
import { AlignJustify } from 'lucide-react'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

export function CommentableElement({
  children,
  pathObject,
  pathId,
  targetPath,
  itemName,
  targetPage,
  targetId,
  directedOrganizationId,
  sx = {},
}: {
  children: ReactNode
  pathObject: string // Type of object (e.g. "rfp", "plant", "job")
  pathId: string // ID of the object
  targetPath: string // Path to the specific field (e.g. "plants.quantity_count.min")
  itemName: string // Display name for the UI
  targetPage: CommentPage // Which page this comment is on ("rfp", "plantlist", "sent_rfp")
  targetId: string // ID of the page target (e.g. RFP ID)
  directedOrganizationId?: string // ID of the organization to direct the comment to
  sx?: SxProps
}) {
  const { isCommentMode, commentType, isLoading } = useCommentStore()
  const [localAnchorEl, setLocalAnchorEl] = useState<HTMLElement | null>(null)

  const { thread, hasComments, isNote, createComment, addComment } = useComments(
    pathObject,
    pathId,
    targetPath,
    targetPage,
    targetId
  )

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (isCommentMode) {
      event.preventDefault()
      event.stopPropagation()
      setLocalAnchorEl(event.currentTarget)
    }
  }

  const handleIndicatorClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setLocalAnchorEl(event.currentTarget.parentElement)

    if (thread) {
      const commentStoreType = isNote ? 'note' : 'comment'
      useCommentStore.getState().setCommentType(commentStoreType)
    }
  }

  const handleClose = () => {
    setLocalAnchorEl(null)
  }

  const handleSubmit = (text: string) => {
    if (commentType) {
      // If there's an existing thread with comments, add to it instead of creating a new one
      if (thread && thread.id !== 'new') {
        addComment(text)
      } else {
        createComment(text, commentType === 'note', directedOrganizationId)
      }
    }
    setLocalAnchorEl(null)
  }

  // Render the comment indicator if there are comments
  const renderCommentIndicator = () => {
    if (!hasComments) return null

    return (
      <Box
        onClick={handleIndicatorClick}
        sx={{
          position: 'absolute',
          right: '-8px',
          top: '-8px',
          background: isNote ? '#FFFFEE' : '#FFFFFF',
          borderRadius: isNote ? '4px' : '50px 50px 50px 0',
          padding: '2px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: isNote ? '#84863E' : 'inherit',
          fontSize: '10px',
          border: '1px solid',
          borderColor: isNote ? '#84863E' : '#CCCCCC',
          zIndex: 1,
          boxShadow: '0px 1px 3px rgba(0,0,0,0.1)',
          cursor: 'pointer',
          '&:hover': {
            boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
            transform: 'translateY(-1px)',
          },
          transition: 'transform 0.2s, box-shadow 0.2s',
          width: 24,
          height: 24,
        }}
      >
        {isNote ? (
          <AlignJustify size={16} strokeWidth={2.5} />
        ) : (
          <Avatar sx={{ width: 16, height: 16, bgcolor: '#538CAF' }}>
            <Typography>{thread?.created_by?.first_name?.[0]}</Typography>
          </Avatar>
        )}
      </Box>
    )
  }

  // If not in comment mode, render children with comment indicator
  if (!isCommentMode) {
    return (
      <Box sx={{ position: 'relative', ...sx }} id={`commentable-${targetPath}`}>
        {children}
        {renderCommentIndicator()}
        {localAnchorEl && thread && (
          <CommentUI
            anchorEl={localAnchorEl}
            onClose={() => {
              handleClose()
              // Reset comment mode when closing
              useCommentStore.getState().setCommentMode(false, null)
            }}
            onSubmit={handleSubmit}
            itemName={itemName}
            commentThread={thread}
          />
        )}
      </Box>
    )
  }

  // In comment mode, make the element commentable
  return (
    <>
      <Box
        onClick={handleClick}
        data-commentable="true"
        sx={{
          position: 'relative',
          outline: '1px dashed #ccc',
          cursor: isLoading ? 'wait' : 'pointer',
          pointerEvents: 'auto', // Explicitly set pointer-events to auto
          backgroundColor: localAnchorEl ? 'rgba(0, 0, 0, 0.05)' : undefined,
          transition: 'background-color 0.2s ease',
          ...sx,
        }}
      >
        {children}
        {renderCommentIndicator()}
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 2,
            }}
          >
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
      {localAnchorEl && (
        <CommentUI
          anchorEl={localAnchorEl}
          onClose={handleClose}
          onSubmit={handleSubmit}
          itemName={itemName}
          commentThread={
            thread || {
              id: 'new',
              comments: [],
              target_path: targetPath,
              comment_specifier: [{ path_object: pathObject, path_id: pathId }],
              target_pages: [{ page: targetPage, target_id: targetId }],
              resolved: false,
              directed_organization: null,
              organization: null as any,
              created_at: null,
              updated_at: null,
              deleted_at: null,
              created_by: null,
              organization_contact: null,
              directed_organization_contact: null,
              send_to_vendor: false,
            }
          }
        />
      )}
    </>
  )
}
