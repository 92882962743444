import React, { useState, useMemo, ReactNode, useEffect } from 'react'
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { DroppableTableGroup, DraggableTableRow, findContainer } from '@/components/ui/draggable-table'
import RfpPlantTableItem from './rfp-plant-table-item.tsx'
import type { PlantListEntry } from '@/types'
import { GlobalEditPlantListEntryModal } from '@/components/ui/modals/global-edit-plant-list-entry-modal.tsx'
import { Box } from '@mui/material'
import theme from '@/theme.ts'
import { PlantTableHeaderCell } from '../ui/base/tables/plant-table.ts'

export type RfpPhaseJobPlantGroup = {
  job_id: string
  phase_id: string
  header: ReactNode
  plants: PlantListEntry[]
  defaultContent: ReactNode
}

export default function RfpPlantTable({
  phaseJobPlantGroup,
  onUpdate,
  onSelectionChange,
  preSelectedPlants,
  allowReorder = false,
  allowQuantityChange = false,
  allowSelect = false,
  showMenu = false,
  showDeleted = false,
}: {
  phaseJobPlantGroup: RfpPhaseJobPlantGroup[]
  onUpdate: (updatedPlants: PlantListEntry[]) => void
  onSelectionChange?: (selectedPlantIds: Set<string>) => void
  preSelectedPlants?: Set<string>
  allowReorder?: boolean
  allowQuantityChange?: boolean
  allowSelect?: boolean
  showMenu?: boolean
  showDeleted?: boolean
}) {
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(preSelectedPlants || new Set())
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editPlant, setEditPlant] = useState<PlantListEntry | null>(null)
  const [activeId, setActiveId] = useState<string | null>(null)

  // TODO: not sure this useEffect is actually necessary, double check
  // sync selectedPlants with preSelectedPlants
  useEffect(() => {
    if (preSelectedPlants) {
      setSelectedPlants(preSelectedPlants)
    }
  }, [preSelectedPlants])

  // Setup DnD sensors
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  // Memoize active plants and plants by phase
  const activePlants = useMemo(
    () => phaseJobPlantGroup.flatMap((group) => group.plants).filter((plant) => showDeleted || !plant.deleted_at),
    [phaseJobPlantGroup, showDeleted]
  )

  const plantsByPhase = useMemo(() => {
    return phaseJobPlantGroup.reduce((acc: Record<string, PlantListEntry[]>, group) => {
      acc[group.phase_id] = group.plants.filter((plant) => showDeleted || !plant.deleted_at)
      return acc
    }, {})
  }, [phaseJobPlantGroup, showDeleted])

  // Find the active plant for drag overlay
  const activePlant = useMemo(() => {
    if (!activeId) return null
    return activePlants.find((plant) => plant.id === activeId)
  }, [activeId, activePlants])

  // Drag handlers
  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    setActiveId(null)

    if (!over) return

    const activeContainer = findContainer(plantsByPhase, active.id as string)
    const overContainer =
      over.data.current?.type === 'GROUP' ? (over.id as string) : findContainer(plantsByPhase, over.id as string)

    if (!activeContainer || !overContainer) return

    // Get the current group's plants
    const activeGroupPlants = plantsByPhase[activeContainer]
    const activeIndex = activeGroupPlants.findIndex((p) => p.id === active.id)
    const overIndex = activeGroupPlants.findIndex((p) => p.id === over.id)

    // If we're in the same container and indexes are different, reorder
    if (activeContainer === overContainer && activeIndex !== overIndex) {
      const updatedPlants = [...activePlants]
      const [movedPlant] = updatedPlants.splice(
        activePlants.findIndex((p) => p.id === active.id),
        1
      )
      const insertIndex = activePlants.findIndex((p) => p.id === over.id)
      updatedPlants.splice(insertIndex, 0, movedPlant)
      onUpdate(updatedPlants)
      return
    }

    // If we're moving to a different container
    if (activeContainer !== overContainer) {
      const targetGroup = phaseJobPlantGroup.find((group) => group.phase_id === overContainer)
      if (!targetGroup) return

      const updatedPlants = activePlants.map((plant) => {
        if (plant.id === active.id) {
          return {
            ...plant,
            phase_id: targetGroup.phase_id,
            job_id: targetGroup.job_id,
          }
        }
        return plant
      })

      onUpdate(updatedPlants)
    }
  }

  // Selection handlers
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = new Set(selectedPlants)
    if (event.target.checked) {
      activePlants.map((plant) => newSelected.add(plant.id))
    } else {
      activePlants.map((plant) => newSelected.delete(plant.id))
    }
    setSelectedPlants(newSelected)
    onSelectionChange?.(newSelected)
  }

  const toggleSelect = (plantId: string) => {
    setSelectedPlants((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(plantId)) {
        newSelected.delete(plantId)
      } else {
        newSelected.add(plantId)
      }

      // Call onSelectionChange with the new selection
      if (onSelectionChange) {
        onSelectionChange(newSelected)
      }

      return newSelected
    })
  }

  // Plant update handlers
  const handlePlantUpdate = (updatedPlant: PlantListEntry) => {
    const updatedPlants = activePlants.map((plant) => (plant.id === updatedPlant.id ? updatedPlant : plant))
    onUpdate(updatedPlants)
  }

  const handleEditEntry = (entry: PlantListEntry) => {
    setEditPlant(entry)
    setEditModalOpen(true)
  }

  const tableContent = (
    <Table stickyHeader>
      <TableHead>
        <TableRow sx={{ backgroundColor: theme.palette.tableHeaderBackground.main }}>
          {allowReorder && <PlantTableHeaderCell padding="none" />}
          {allowSelect && (
            <PlantTableHeaderCell padding="checkbox" sx={{ py: 0 }}>
              <Checkbox
                checked={activePlants.length > 0 && activePlants.every((p) => selectedPlants.has(p.id))}
                indeterminate={
                  activePlants.some((p) => selectedPlants.has(p.id)) &&
                  !activePlants.every((p) => selectedPlants.has(p.id))
                }
                onChange={handleSelectAll}
              />
            </PlantTableHeaderCell>
          )}
          <PlantTableHeaderCell padding="none" sx={{ pl: 2 }}>
            <Typography sx={{ width: '100%' }} variant="body2" fontWeight={400}>
              Plant Name
            </Typography>
          </PlantTableHeaderCell>
          <PlantTableHeaderCell sx={{ whiteSpace: 'nowrap', width: '1%' }}>
            <Typography variant="body2" fontWeight={400}>
              Specs
            </Typography>
          </PlantTableHeaderCell>
          <PlantTableHeaderCell sx={{ whiteSpace: 'nowrap', width: '1%' }}>
            <Typography variant="body2" fontWeight={400}>
              QTY Needed
            </Typography>
          </PlantTableHeaderCell>
          <PlantTableHeaderCell sx={{ whiteSpace: 'nowrap', width: '1%' }} padding="none" />
        </TableRow>
      </TableHead>

      {phaseJobPlantGroup.map((group) => {
        const phasePlants = plantsByPhase[group.phase_id] || []
        const groupDisabled = false

        return (
          <DroppableTableGroup
            key={group.phase_id}
            groupId={group.phase_id}
            items={phasePlants.map((p) => p.id)}
            disabled={groupDisabled}
          >
            {group.header && (
              <TableRow>
                <TableCell colSpan={6} sx={{ pr: 0, py: theme.spacing(1) }}>
                  {group.header}
                </TableCell>
              </TableRow>
            )}

            {phasePlants.length > 0 ? (
              phasePlants.map((plant) => (
                <DraggableTableRow
                  key={plant.id}
                  id={plant.id}
                  groupId={group.phase_id}
                  dragHandle={allowReorder}
                  disabled={groupDisabled}
                  sx={{
                    opacity: plant.deleted_at ? 0.7 : 1,
                  }}
                >
                  <RfpPlantTableItem
                    plant={plant}
                    onUpdate={handlePlantUpdate}
                    isSelected={selectedPlants.has(plant.id)}
                    onSelect={toggleSelect}
                    onEditEntry={handleEditEntry}
                    allowQuantityChange={allowQuantityChange}
                    allowSelect={allowSelect}
                    showMenu={showMenu}
                    showDeleted={showDeleted}
                  />
                </DraggableTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ pr: 0 }}>
                  {group.defaultContent}
                </TableCell>
              </TableRow>
            )}
          </DroppableTableGroup>
        )
      })}
    </Table>
  )

  return (
    <>
      {allowReorder ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {tableContent}
          <DragOverlay>
            {activePlant && (
              <Box
                sx={{
                  padding: '16px',
                  background: 'white',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  borderRadius: '4px',
                  width: 'fit-content',
                  '&:hover': {
                    cursor: 'grabbing',
                  },
                }}
              >
                {activePlant.common_name || activePlant.scientific_name}
              </Box>
            )}
          </DragOverlay>
        </DndContext>
      ) : (
        tableContent
      )}

      {editPlant && (
        <GlobalEditPlantListEntryModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          entry={editPlant}
          onUpdate={handlePlantUpdate}
        />
      )}
    </>
  )
}
